import React from 'react'
import { Container, Flex, Link as A, Text } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import {
  Headline,
  Highlight,
  FaqHeader,
  CareerRow,
  contentContainer,
  content,
  CareerSectionLead,
  PrivacyBackground
} from 'components/Content'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'

A.link = A.withComponent(Link)

const title = 'Privacy Policy- Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/privacy/"
  >
    <Nav />
    <Social />
    <PrivacyBackground px={3} pt={4}>
      <FaqHeader pt={[4, 5, 6]}></FaqHeader>
      <Flex justify="center" color="white">
        <Container width={1} py={[4, 5]} align={['left', 'center']}>
          <Headline px={3} mt={[3, 4]} mb={[4, 5]} mx="auto">
            Privacy Policy
          </Headline>
        </Container>
      </Flex>
    </PrivacyBackground>
    <Container {...contentContainer}>
      <CareerSectionLead>
        Coding Sastra ​recognises the importance of maintaining your privacy. We
        value your privacy and appreciate your trust in us. This Policy
        describes how we treat user information we collect on
        <Highlight> ​http://www.codingsastra.com</Highlight> and other offline
        sources. This Privacy Policy applies to current and former visitors to
        our website and to our online customers. By visiting and/or using our
        website, you agree to this Privacy Policy.
      </CareerSectionLead>
      <CareerSectionLead>
        <Highlight>codingsastra.com</Highlight> is a property of ​Codingsastra,
        an Indian Company registered under the Companies Act, 2015 having its
        registered office at HIG-129, Pramani Plaza, 5th Floor, Phase 1&2
        Kukatpally, KPHB, Hyderabad, TS 500082
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Information we collect</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        Contact information.​We might collect your​name, email:
        hi@codingsastra.com, mobile number: +917337213134, phone number, street,
        city, state, pincode, country and ip address.
        <br /> <br />
        We NEVER collect your credit card number or credit card expiry date or
        other details pertaining to your credit card on our website.
        <br /> <br /> Information you post.​We collect information you post in a
        public space on our website or on a third­party social media site
        belonging to CodingSastra.com <br /> <br />
        Demographic information.​We may collect demographic information about
        you, events you like, events you intend to participate in, tickets you
        buy, or any other information provided by your during the use of our
        website. We might collect this as a part of a survey also.
        <br /> <br /> Other information.​If you use our website, we may collect
        information about your IP address and the browser you’re using. We might
        look at what site you came from, duration of time spent on our website,
        pages accessed or what site you visit when you leave us. We might also
        collect the type of mobile device you are using, or the version of the
        operating system your computer or device is running.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>We collect information in different ways.</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        We collect information directly from you.​We collect information
        directly from you when you register for an event or buy tickets. We also
        collect information if you post a comment on our websites or ask us a
        question through phone or email.
        <br /> <br /> We collect information from you passively.​We use tracking
        tools like Google Analytics, Google Webmaster, browser cookies and web
        beacons for collecting information about your usage of our website.
        <br /> <br /> We get information about you from third parties.​For
        example, if you use an integrated social media feature on our websites.
        The third­ party social media site will give us certain information
        about you. This could include your name and email address.
        <br /> <br /> Use of your personal information
        <br /> <br /> We use information to contact you: ​We might use the
        information you provide to contact you for confirmation of a purchase on
        our website or for other promotional purposes. We use information to
        respond to your requests or questions.​We might use your information to
        confirm your registration for an event or contest.
        <br /> <br /> We use information to improve our products and
        services.​We might use your information to customize your experience
        with us. This could include displaying content based upon your
        preferences.
        <br /> <br /> We use information to look at site trends and customer
        interests.​We may use your information to make our website and products
        better. We may combine information we get from you with information
        about you we get from third parties.
        <br /> <br /> We use information for security purposes.​We may use
        information to protect our company, our customers, or our websites.
        <br /> <br /> We use information for marketing purposes.​We might send
        you information about special promotions or offers. We might also tell
        you about new features or products. These might be our own offers or
        products, or third­party offers or products we think you might find
        interesting. Or, for example, if you buy tickets from us we’ll enroll
        you in our newsletter.
        <br /> <br /> We use information to send you transactional
        communications.​We might send you emails or SMS about your account or a
        ticket purchase.
        <br /> <br /> We use information as otherwise permitted by law.
      </CareerSectionLead>

      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Sharing of information with third­parties</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        We will share information with third parties who perform services on our
        behalf. ​We share information with vendors who help us manage our online
        registration process or payment processors or transactional message
        processors. Some vendors may be located outside of India.
        <br /> <br /> We will share information with the event organizers.​We
        share your information with event organizers and other parties
        responsible for fulfilling the purchase obligation. The event organizers
        and other parties may use the information we give them as described in
        their privacy policies.
        <br /> <br /> We will share information with our business partners.​This
        includes a third party who provide or sponsor an event, or who operates
        a venue where we hold events. Our partners use the information we give
        them as described in their privacy policies.
        <br /> <br /> We may share information if we think we have to in order
        to comply with the law or to protect ourselves.​We will share
        information to respond to a court order or subpoena. We may also share
        it if a government agency or investigatory body requests. Or, we might
        also share information when we are investigating potential fraud.
        <br /> <br /> We may share information with any successor to all or part
        of our business.​For example, if part of our business is sold we may
        give our customer list as part of that transaction.
        <br /> <br /> We may share your information for reasons not described in
        this policy.​We will tell you before we do this.
      </CareerSectionLead>

      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Email Opt­Out</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        You can opt out of receiving our marketing emails.​To stop receiving our
        promotional emails, please email hi@codingsastra.com It may take about
        ten days to process your request. Even if you opt out of getting
        marketing messages, we will still be sending you transactional messages
        through email and SMS about your purchases.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Third party sites</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        If you click on one of the links to third party websites, you may be
        taken to websites we do not control. This policy does not apply to the
        privacy practices of those websites. Read the privacy policy of other
        websites carefully. We are not responsible for these third party sites.
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Grievance Officer</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        In accordance with Information Technology Act 2000 and rules made there
        under, the name and contact details of the Grievance Officer are
        provided below: <br />
        <br />
        <Highlight>
          Mr. Varma Bhupatiraju <br />
          HIG-129, Pramani Plaza, 5th Floor
          <br /> Phase 1&2 Kukatpally,
          <br />
          KPHB, Hyderabad, TS 500082,
          <br /> Phone: +91 9951013134 <br />
          Email: ​hi@codingsastra.com
          <br />
        </Highlight>{' '}
        If you have any questions about this Policy or other privacy concerns,
        you can also email us at hi@codingsastra.com
      </CareerSectionLead>
      <CareerRow my={5} {...content}>
        <Text mb={0} fontSize={4} color="pink.6">
          <strong>Updates to this policy</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        This Privacy Policy was last updated on 12.01.2016. From time to time we
        may change our privacy practices. We will notify you of any material
        changes to this policy as required by law. We will also post an updated
        copy on our website. Please check our site periodically for updates.
      </CareerSectionLead>
      <CareerRow my={5} {...content} fontSize={4}>
        <Text mb={0} color="pink.6">
          <strong>Jurisdiction</strong>
        </Text>
      </CareerRow>
      <CareerSectionLead>
        If you choose to visit the website, your visit and any dispute over
        privacy is subject to this Policy and the website’s terms of use. In
        addition to the foregoing, any disputes arising under this Policy shall
        be governed by the laws of India.
      </CareerSectionLead>
    </Container>
    <Footer />
  </Layout>
)

export const query = graphql`
  query privacyMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
